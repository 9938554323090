export const courses = [
  {
    image: "../imgs/Visvasakshi.png",
    title:"Viśva-sākṣī",
    content: [
      {
        pointNo: 1,
        desc:
          "Viśva-sākṣī is an assisted online program tailored for children and youth aged 10 to 14 and 15 to 21. This program offers twice a week live online sessions accompanied by comprehensive thematic modules covering 10 Value-based Themes.",
      },
      {
        pointNo: 2,
        desc:
          "Participants delve into Patanjali’s Yoga Sutras, selected slokas from the Bhagavad Gita, and the Ashtavakra Gita, exploring profound teachings through engaging discussions and interactive assignments.",
      },
    ],
    registerLink:
      "https://heartfulness.org/education/visvasakshi-2024/",
  },
  {
    image: "../imgs/Sthitaprajna.png",
    title:"Sthitaprajña",
    content: [
      {
        pointNo: 1,
        desc:
          "Sthitaprajña is an assisted online program tailored for adults aged 22 and above. It features twice-weekly live online sessions correlating the teachings of the Bhagavad Gita with core Heartfulness practices like relaxation, meditation, rejuvenation, and inner connect.",
      },
      {
        pointNo: 2,
        desc:
          "Scholar experts provide insights and guidance, fostering a deeper understanding of the Gita’s principles.",
      },
    ],
    registerLink:
      "https://heartfulness.org/education/sthitaprajna-2024/",
  },
  {
    image: "../imgs/prajna-image.png",
    title:"Prajña",
    content: [
      {
        pointNo: 1,
        desc:
          "Prajña is a self-paced online course designed for children aged 5 to 15. It offers a structured learning journey through 120 selected slokas of the Bhagavad Gita, divided into 20 thematic modules.",
      },
      {
        pointNo: 2,
        desc:
          "Each module explores essential life concepts, such as “Self-Mastery”, “Attitude Towards Food”, and “Exploring Inner Potential”, using interactive activities.",
      },
    ],
    registerLink: "https://heartfulness.org/education/prajna-2024/",
  },
  // {
  //   image: "../imgs/vidyarthi.png",
  //   title:"Vidyārthi",
  //   content: [
  //     {
  //       pointNo: 1,
  //       desc:
  //         "Vidyārthi is an assisted online program designed for children aged 5 to 15. Vidyarthi features twice a week live online sessions complemented by comprehensive thematic modules covering 120 selected shlokas of the Bhagavad Gita along with audio tutorials and assignments.",
  //     },
  //     {
  //       pointNo: 2,
  //       desc:
  //         "Participants engage in interactive discussions, hands-on assignments, and self-paced study to deepen their understanding of key life concepts.",
  //     },
  //   ],
  //   registerLink:
  //     "https://awsstaging.heartfulness.org/education/gitopadesh-notify/",
  // },
  // {
  //   image: "../imgs/vidyarthi.png",
  //   title:"Vidyārthi Plus",
  //   content: [
  //     {
  //       pointNo: 1,
  //       desc:
  //         "Vidyārthi Plus is an assisted online course designed for children aged 5 to 15 years that uses Workbooks designed for the entire family. It features once-a-week live online sessions complemented by 20 value-based themes covering the Bhagavad Gita.",
  //     },
  //     {
  //       pointNo: 2,
  //       desc:
  //         "These workbooks include interactive exercises and contemplative assignments with pre-recorded video tutorials accessed through QR codes. The workbooks are designed to energize the Wisdom Bridge between the generations towards learning the key life concepts.",
  //     },
  //   ],
  //   registerLink:
  //     "https://awsstaging.heartfulness.org/education/gitopadesh-notify/",
  // },
];
