/** @jsx jsx */
import { Box, jsx } from "theme-ui";
import React, { useCallback } from "react";
import { PageContentNonMemoized } from "gatsby-plugin-hfn-profile/components/PageContentNonMemoized";
import { useQueryParam, StringParam } from "use-query-params";
import DonationLayout from "../layout/DonationLayout";
import { eventCallbackTriggers } from "../templates/wppage";
import { courses } from "../../static/data/gitopadesh-courses";

const Gitopadesh = () => {
  const [status] = useQueryParam("status", StringParam);

  const pageContent = (id, modalTitle) => {
    const titletext = modalTitle || "Heartfulness Donation";
    return `<gatsby_donation donationId = "${id}" btntext = "${titletext}" btnBg = #ef1850 colortext = black colorprimary = #43190b colorsecondary = #43190b colorbackground = white colormuted = #f6f6f6 colorhighlight = #efeffe titletext = "Heartfulness Donation" />`;
  };

  const PageContent = React.memo(PageContentNonMemoized);

  const eventCallback = useCallback(
    (event) => eventCallbackTriggers("Heartfulness_Donation", event),
    []
  );
  return (
    <DonationLayout
      seoData={{
        title: "GITOPADESH COURSES - Heartfulness Education Trust",
      }}
    >
      <Box
        sx={{
          backgroundColor: "#43190b",
          textAlign: "center",
          paddingTop: "10px",
        }}
      >
        {status === "success" && (
          <p
            sx={{
              color: "white",
            }}
          >
            Thank vou for registering. Gitopadesh program proctor will contact
            vou shortly.
          </p>
        )}
        <h3
          sx={{
            color: "white",
          }}
        >
          Pay Forward - Your contribution can help us take Gitopadesh to many
          more hearts!
        </h3>
        <Box>
          <PageContent
            eventCallback={eventCallback}
            pageContent={pageContent(36, "DONATE NOW")}
          />
        </Box>
      </Box>
      <section>
        <Box
          sx={{
            backgroundRepeat: "no-repeat",
            backgroundImage: `url(http://awsstaging.heartfulness.org/education/wp-content/uploads/2021/10/Untitled-1@2x.png)`,
            minHeight: "448px",
            position: "relative",
            backgroundSize: "cover",
            backgroundPosition: "top center",
            "@media screen and (max-width: 540px)": {
              backgroundPosition: "top center",
              minHeight: "280px",
            },
          }}
          className="row banner-bg"
        >
          <div
            className="col-md-12 btn-btm"
            sx={{
              position: "absolute",
              bottom: "3%",
              width: "100%",
              textAlign: "center",
              "@media screen and (max-width: 540px)": {
                bottom: "0%",
                textAlign: "center",
              },
            }}
          />
        </Box>
      </section>

      <section className="py-5 bg-color" sx={{ backgroundColor: "#f6f7fb" }}>
        <div
          className="container"
          sx={{
            "@media (min-width:768px) and (max-width:912px)": {
              maxWidth: "inherit",
            },
          }}
        >
          <div className="row pb-4">
            <div className="col-md-12 text-center ">
              <h2
                className="gotham-bold"
                sx={{
                  letterSpacing: "0px",
                  color: "#2B2B2B",
                  opacity: "1",
                  fontWeight: "bolder !important",
                  textDecoration: "underline",
                }}
              >
                Individual Courses
              </h2>
            </div>
          </div>

          <div className="row gap d-flex justify-content-center">
            {courses &&
              courses?.map((i) => (
                <div className="col-lg-6 col-md-12 text-center my-1">
                  <div
                    className="bg-white p-4"
                    sx={{
                      background: "#FFFFFF 0% 0% no-repeat padding-box",
                      boxShadow: "0px 6px 18px #00000021",
                      borderRadius: "10px",
                      opacity: "1",
                      height: "100%",
                      "@media (min-width:768px) and (max-width:912px)": {
                        padding: "18px 0px",
                      },
                    }}
                  >
                    <div className="title-img text-center py-2">
                      <img
                        loading="lazy"
                        src={i?.image}
                        alt="..."
                        width="146px"
                        height="146px"
                        id="img-m"
                      />
                    </div>
                    <p
                      className="my-3"
                      sx={{
                        // fontFamily: "gotham-bold !important",
                        letterSpacing: "0px",
                        opacity: "1",
                        fontSize: "24px",
                        fontWeight: "bolder !important",
                        "@media (min-width:768px) and (max-width:912px)": {
                          fontSize: "19px",
                        },
                      }}
                    >
                      {i?.title}
                    </p>
                    <div
                      sx={{
                        minHeight: "450px",
                        "@media (min-width:400px) and (max-width:1000px)": {
                          minHeight: "300px",
                        },
                        "@media (min-width:1200px)": {
                          minHeight: "270px",
                        },
                      }}
                    >
                      {i?.content &&
                        i?.content?.map((cont) => (
                          <div
                            sx={{
                              textAlign: "start !important",
                              display: "flex",
                              fontSize: "16px",
                            }}
                          >
                            <span sx={{ marginRight: "10px !important" }}>
                              {cont?.pointNo}.{" "}
                            </span>{" "}
                            <p>{cont?.desc}</p>
                          </div>
                        ))}
                    </div>
                    <p className="button-reg py-2">
                      <a
                        href={i?.registerLink}
                        className="reg-btn text-decoration-none text-white"
                        target="blank"
                        sx={{
                          padding: "12px 26px",
                          background: "#29BBF8 0% 0% no-repeat padding-Box",
                          borderRadius: "6px",
                          opacity: "1",
                          // fontFamily: "gotham-book !important",
                          letterSpacing: "0px",
                          color: "#FFFFFF",
                          ":hover": { color: "#FFFFFF" },
                        }}
                      >
                        Register Now
                      </a>
                    </p>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </section>
    </DonationLayout>
  );
};

export default Gitopadesh;
